import { DEFAULT_LANGUAGE } from './feature-flags';
/**
 * ************************************************
 * Time & Language Property
 * ************************************************
 */
import dayjs from 'dayjs';
import 'dayjs/locale/id';
import 'dayjs/locale/en';

export const PASSWORD_LINK_GENERATOR = 'pernikahanini';

/**
 * ************************************************
 * Flag to to set Bride to BOY first
 * ************************************************
 */
export const IS_BOY_FIRST = false;

/**
 * ************************************************
 * Bride & Groom Info
 * ************************************************
 */
export const GIRL_NAME = 'Annisa Nur Aziziyah Waluyo, S.M.';
export const GIRL_NAME_SHORT = 'Annisa';
export const GIRL_FATHER_NAME = `Iswaluyo, S.Pd`;
export const GIRL_MOTHER_NAME = `Woro Kurniati, S.Pd`;
export const GIRL_PARENT_NAME =
  DEFAULT_LANGUAGE === 'en'
    ? `The Daughter of: <br /> Mr. ${GIRL_FATHER_NAME} <br /> and Mrs. ${GIRL_MOTHER_NAME}`
    : `Putri dari: <br />Bapak ${GIRL_FATHER_NAME} <br /> dan Ibu ${GIRL_MOTHER_NAME}`;

// -> boy section
export const BOY_NAME = 'Dzulian Zhidan Nassa Pratama, S.M.';
export const BOY_NAME_SHORT = 'Zhidan';
export const BOY_FATHER_NAME = `Mashud Yunasa, S.H.`;
export const BOY_MOTHER_NAME = `Santi Novalia Herawati`;
export const BOY_PARENT_NAME =
  DEFAULT_LANGUAGE === 'en'
    ? `The Son of:<br /> Mr. ${BOY_FATHER_NAME} <br /> and Mrs. ${BOY_MOTHER_NAME}`
    : `Putra dari:<br /> Bapak ${BOY_FATHER_NAME}<br /> dan Ibu ${BOY_MOTHER_NAME}`;

// -> bride section
export const BRIDE_HASHTAG = `#ANNItimewithZHIDAN`;
export const QUOTES = `“…They are Clothing for you and you are clothing for them.” <br />- Quran 2:187 -`;
export const THE_BRIDE = IS_BOY_FIRST
  ? `${BOY_NAME_SHORT} & ${GIRL_NAME_SHORT}`
  : `${GIRL_NAME_SHORT} & ${BOY_NAME_SHORT}`;

/**
 * ************************************************
 * Instagram Profile Account
 * @important please put instagram id without `@`
 * ************************************************
 */
export const IG_BOY = 'dzulianzhidan';
export const IG_GIRL = 'nisazyh';
export const IG_FILTER_URL = `https://www.instagram.com/ar/1997510623969669/?ch=YmJiM2NhMTY0MzljMTAwMGI1NGRlZDYzZGQ2M2YzNmE%3D`;

/**
 * ************************************************
 * SEO Requirement
 * @important - Don't forget to update SEO IMAGE
 * ************************************************
 */
export const SEO_IMAGE = `https://ik.imagekit.io/zaanfa/annisa-dzulian/seo_fpPPtgSSlJ.jpg?updatedAt=1709610034544`;
export const SEO_URL = 'https://invitato.net/akadcintaastridanakbar/';
export const SEO_TITLE = `The Wedding of ${THE_BRIDE} by Invitato`;
export const SEO_DESCRIPTION =
  DEFAULT_LANGUAGE === 'en'
    ? `Together with joyful hearts, we re pleased to announce the beginning of this new chapter of our lives together. Please click the Website Invitation link on this message for more information details.`
    : `Menjadi sebuah kebahagiaan bagi kami untuk mengumumkan awal dari babak baru kehidupan kami bersama. Silakan klik tautan situs Undangan Website di bawah untuk informasi lebih lanjut:`;

/**
 * ************************************************
 * Time requirement for Counting Down, and
 * Remind me generator
 * @important - please convert time to Epoch time by
 * using this link https://www.epochconverter.com/
 * ************************************************
 */
export const EPOCH_START_EVENT = 1713488400;
export const EPOCH_RESEPSI_START = 1713488400;
export const EPOCH_END_EVENT = 1713533400;

/**
 * ************************************************
 * DATE REQUIREMENT
 * @important - Format date YYYY-MM-DD
 * ************************************************
 */
export const DATE_AKAD = new Date('2024-04-19');
export const DATE_RESEPSI = new Date('2024-04-19');

export const WEDDING_AKAD_TIME = '08.00 WIB';
export const WEDDING_AKAD_LOC_NAME = 'Nusantara Ballroom Hotel JW Marriott';
export const WEDDING_AKAD_LOC_ROAD = `Jl. Embong Malang No. 85-89, Kedungdoro, Surabaya`;
export const WEDDING_AKAD_DRESSCODE = `Formal`;
export const WEDDING_AKAD_NOTE = ``;
export const WEDDING_AKAD_DAY = dayjs(DATE_AKAD)
  .locale(DEFAULT_LANGUAGE)
  .format('dddd');
export const WEDDING_AKAD_FULLDATE = dayjs(DATE_AKAD)
  .locale(DEFAULT_LANGUAGE)
  .format('DD MMMM YYYY');
// in case you need custom format for full date, just uncomment below section
// export const WEDDING_AKAD_FULLDATE = `Saturday, June 18<sup>th</sup>, 2022`;

export const WEDDING_RESEPSI_TIME = '18.30 WIB';
export const WEDDING_RESEPSI_LOC_NAME = 'Royal Ballroom Hotel JW Mariott';
export const WEDDING_RESEPSI_LOC_ROAD = 'Jl. Embong Malang No. 85-89, Kedungdoro, Surabaya';
export const WEDDING_RESEPSI_DRESSCODE = 'Formal';
export const WEDDING_RESEPSI_NOTE = ``;
export const WEDDING_RESEPSI_DAY = dayjs(DATE_RESEPSI)
  .locale(DEFAULT_LANGUAGE)
  .format('dddd');
export const WEDDING_RESEPSI_FULLDATE = dayjs(DATE_RESEPSI)
  .locale(DEFAULT_LANGUAGE)
  .format('DD MMMM YYYY');

/**
 * ********************************************************
 * Link Generator V2
 * @important - this info will be applied at link generator
 * ********************************************************
 */
export const HOSTNAME = 'https://annisazhidan.com/';
export const BROADCAST_WEDDING_LOCATION = `Royal Ballroom Hotel JW Mariott, Surabaya`;
export const BROADCAST_WEDDING_DAY = {
  id: dayjs(DATE_RESEPSI).locale('id').format('dddd, DD MMMM YYYY'),
  en: dayjs(DATE_RESEPSI).locale('en').format('dddd, DD MMMM YYYY'),
};

/**
 * ************************************************
 * Maps Location
 * ************************************************
 */
export const GOOGLE_MAPS_AKAD = `https://maps.app.goo.gl/9GZeCaYB4Pb3ZLRd8`;
export const GOOGLE_MAPS_RESEPSI = `https://maps.app.goo.gl/9GZeCaYB4Pb3ZLRd8`;
export const GOOGLE_MAPS_ADDRESS = `JW Marriott Hotel Surabaya`;

/**
 * ************************************************
 * Backsound Copyright
 * ************************************************
 */
export const SOUND_BY = `Bird Sound by Relaxation Music`;
export const SOUND_URL = 'https://youtu.be/-i6Y0sQ15gc?si=lXCWsEurfm2uhaPD';

/**
 * ************************************************
 *  Youtube Live requirement
 * ************************************************
 */
export const YOUTUBE_LINK = 'https://cutt.ly/ZhidanAnnisaAkad';
export const YOUTUBE_EMBED = 'https://www.youtube.com/embed/vEsYMaEqIiE?si=X1I4JLPxq4boTiPi';
export const PREWEDDING_LINK = 'https://youtu.be/Dmco_VWO81Y';
export const PREWEDDING_EMBED = 'https://www.youtube.com/embed/Dmco_VWO81Y';

/**
 * ************************************************
 *  Invitato Link Requirement
 * ************************************************
 */
export const INVITATO_URL = 'https://invitato.id';
export const URL_INVITATO = 'https://invitato.id';
export const INVITATO_IG_URL = 'https://www.instagram.com/invitato.id/';
export const URL_WA_INVITATO = 'https://wa.me/+628112778189?text=Hi%20Kak,%20aku%20mau%20pesan%20undangan%20online%20nih!';